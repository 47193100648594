import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {QueryResult, RemitApiResponse} from '..';
import {QINVENTORY_BASE_URL} from './config';


export interface RemitInventoryLocationCreateForm{
  name: string
  shopCode: string
}

export interface RemitInventoryLocation{
  id: string
  name: string
  shopCode: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface StockBatchModifyForm{
  locationId: string
  items: InventoryItemForm[]
}

export interface StockBatchTransferForm{
  sourceId: string
  destinationId: string
  items: InventoryItemForm[]
}

export interface InventoryItemForm{
  sku: string
  quantity: number
}

export interface StockInfo{
  id: string
  productId: string
  locationId: string
  quantity: number
  createdAt?: string
  createdBy?: string
  updatedAt?: string
  updatedBy?: string
}


export interface SupplierForm{
  name?: string // required
  contactPerson?: string
  telp1?: string
  telp2?: string
  fax?: string
  address?: string
  email?: string
}

export interface Supplier{
  id: string
  name: string
  contactPerson?: string
  telp1?: string
  telp2?: string
  fax?: string
  address?: string
  email?: string
}

export interface SupplierQueryParams{
  pageNumber?: number | null
  pageSize?: number | null
}


export interface PurchaseOrderForm{
  supplierId: string
  locationId: string
  items: Partial<PurchaseOrderItem>[]
  note?: string
}


export interface PurchaseOrder{
  id: string
  supplierId: string
  locationId: string
  items?: Partial<PurchaseOrderItem>[]
  note?: string
  address?: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  shopCode?: string
  locationName?: string
  supplierName?: string
}

export interface PurchaseOrderItem{
  sku: string
  quantity: number
  price: number
}


@Injectable({
  providedIn: 'root',
})
export class RemitShopInventoryService {
  constructor(
    private http: HttpClient,
    @Inject(QINVENTORY_BASE_URL) private remitApiUrl: string,
  ) { }

  createInventoryLocation(data: Partial<RemitInventoryLocationCreateForm>):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/location',
        data,
    );
  }

  getInventoryLocation(id: string): Observable<RemitInventoryLocation> {
    return this.http.get<{body: RemitInventoryLocation}>(
        this.remitApiUrl + '/location/' + id,
    ).pipe(map((v)=> v.body));
  }

  updateInventoryLocation(
      id: string, data: Partial<RemitInventoryLocationCreateForm>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + '/location/' + id,
        data,
    );
  }

  queryInventoryLocation(params: {
    pageNumber: number
    pageSize: number
    sortBy?: string
    sortDirection?: number | null
  }): Observable<QueryResult<RemitInventoryLocation>> {
    return this.http.post<QueryResult<RemitInventoryLocation>>(
        this.remitApiUrl + '/locations',
        params,
    );
  }

  getLocationId(shopCode: string): Observable<string> {
    return this.queryInventoryLocation({
      pageNumber: 0, pageSize: 100,
    }).pipe(map((res)=>{
      return res.body.find((v)=> v.shopCode == shopCode)?.id ?? '';
    }));
  }

  addStock(params:{
    locationId: string,
    sku: string,
    quantity: number,
  }):Observable<RemitApiResponse> {
    return this.increaseStockBatch({
      locationId: params.locationId,
      items: [{
        sku: params.sku,
        quantity: params.quantity,
      }],
    });
  }

  increaseStockBatch(data: StockBatchModifyForm):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/trx/add',
        data,
    );
  }


  removeStock(params:{
    locationId: string,
    sku: string,
    quantity: number,
  }):Observable<RemitApiResponse> {
    return this.decreaseStockBatch({
      locationId: params.locationId,
      items: [{
        sku: params.sku,
        quantity: params.quantity,
      }],
    });
  }

  decreaseStockBatch(data: StockBatchModifyForm):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/trx/remove',
        data,
    );
  }

  setStock(params:{
    locationId: string,
    sku: string,
    quantity: number,
  }):Observable<RemitApiResponse> {
    return this.setStockBatch({
      locationId: params.locationId,
      items: [{
        sku: params.sku,
        quantity: params.quantity,
      }],
    });
  }

  setStockBatch(data: StockBatchModifyForm):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/trx/set',
        data,
    );
  }

  transferStockBatch(data: StockBatchTransferForm):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/trx/transfer',
        data,
    );
  }

  getInventoryQuantity(locationId: string, sku: string):
  Observable<StockInfo> {
    return this.http.get<{body: StockInfo}>(
        this.remitApiUrl + '/stock/' + sku + '/location/' + locationId,
    ).pipe(map((v)=> v.body));
  }

  createSupplier(data: Partial<SupplierForm>): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/supplier',
        data,
    );
  }

  getSupplier(id: string): Observable<Supplier> {
    return this.http.get<{body: Supplier}>(
        this.remitApiUrl + '/supplier/' + id,
    ).pipe(map((v)=> v.body));
  }

  updateSupplier(id: string, data: Partial<SupplierForm>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + '/supplier/' + id,
        data,
    );
  }

  deleteSupplier(id: string): Observable<RemitApiResponse> {
    return this.http.delete<RemitApiResponse>(
        this.remitApiUrl + '/supplier/' + id,
    );
  }

  querySuppliers(params: SupplierQueryParams):
  Observable<QueryResult<Supplier>> {
    return this.http.post<QueryResult<Supplier>>(
        this.remitApiUrl + '/suppliers',
        params,
    );
  }

  createPurchaseOrder(data: Partial<PurchaseOrderForm>):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/purchasing-order',
        data,
    );
  }

  getPurchaseOrder(id: string): Observable<PurchaseOrder> {
    return this.http.get<{body: PurchaseOrder}>(
        this.remitApiUrl + '/purchasing-order/' + id,
    ).pipe(map((v)=> v.body));
  }

  queryPurchaseOrders(params: {
    pageNumber?: number | null
    pageSize?: number | null
    sortBy?: string | null
    sortDirection?: number | null
  }): Observable<QueryResult<PurchaseOrder>> {
    return this.http.post<QueryResult<PurchaseOrder>>(
        this.remitApiUrl + '/purchasing-orders',
        params,
    );
  }

  updatePurchaseOrder(id: string, data: Partial<PurchaseOrderForm>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + '/purchasing-order/' + id,
        data,
    );
  }
}
