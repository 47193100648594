/* eslint-disable max-len */
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {MsjhFontTtfResolver} from '@indosuara/msjh-font';
import {format, parseISO} from 'date-fns';
import {saveAs} from 'file-saver';
import jsPDF from 'jspdf';
import * as _ from 'lodash';
import {EMPTY, Observable, combineLatest, from, map, merge, of, switchMap, tap} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';
import {RemitInvoice, RemitInvoiceService, RemitInvoices} from './remit-invoice.service';
export interface CreateBankReportRequest {
  taiwanMiddleBankId: string
  invoiceIds: string[]
}

export interface RemitBankReportPagination {
  pageSize?: number
  pageNumber?: number
}

export interface RemitBankReport{
  bankReportId: string
  taiwanMiddleBankId: string
  createdAt: string
  createdBy: string
  invoices: RemitInvoice[]
  downloadCount?: number
}
export interface RemitBankReports{
  body: RemitBankReport[]
  totalData: number
}

export interface RemitTaiwanMiddleBank{
  id: string
  name: string
}


export interface RemitBankReportTaichungFormat{
  委託人: string
  國籍: string
  外僑居留號碼: string
  外僑居留證有效期限: string
  委託人在臺居所電話: string
  委託結匯金額: string
  受款人姓名: string
  受款人國籍: string
  註冊日期: string
  最後資料修改日期: string
  收據號碼: string
  匯款人生日: string
  收款人銀行: string
  收款人銀行帳號: string
}

export interface RemitBankReportBRIFormat{
  委託人居留證號碼: string
  居留證有效期限: string
  委託人生日: string
  委託人在臺電話: string
  委託結匯金額: number
  委託人姓名: string
  委託人國籍: string
  受款人生日: string
  受款人姓名: string
  受款人國籍: string
  受款國家: string,
  受款銀行: string,
}

export interface RemitBankReportSinopacFormat{
  no: string
  nameOfForeigner: string
  nationalities: string
  arcNo: string
  arcExpiryDate: string
  taiwanPhoneNo: string
  amountOfMandatedSettlement: number
  equivalentUSD: string
  beneficiaryBank: string
  beneficiaryAccountNo: string
  beneficiaryAccountName: string
  beneficiaryPhoneNo: string
  remitterDateOfBirth: string
  remitterGender: string
  beneficiaryNationalities: string
  beneficiaryDateOfBirth: string
  beneficiaryGender: string
}

export interface RemitBankReportShinkongFormat{
  no: string
  nameOfForeigner: string
  nationalities: string
  arcNo: string
  arcExpiryDate: string
  telNoTaiwan: string
  twdDollars: number
  equivalentIDR: number
  beneficiaryBank: string
  beneficiaryAccountNo: string
  beneficiaryAccountName: string
  senderBirthday: string
  beneficiaryBirthday: string
  beneficiaryNationalities: string
  remittanceDate: string
  taiwanCompanyIdNo: string
}


@Injectable({
  providedIn: 'root',
})
export class RemitBankReportService {
  constructor(
    private http: HttpClient,
    private fontResolver: MsjhFontTtfResolver,
    private invoiceService: RemitInvoiceService,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  private base64Cache: { [url: string]: string } = {};

  undoReported(trxId: string):Observable<RemitApiResponse> {
    return this.http
        .put<RemitApiResponse>(
            this.remitApiUrl+
            `/staff/remit-invoice/${trxId}/undo-bankreport`,
            null,
        );
  }

  createNewBankReport(
      data: CreateBankReportRequest):
      Observable<RemitApiResponse> {
    return this.http
        .post<RemitApiResponse>(this.remitApiUrl+`/staff/bankreport`, data);
  }
  queryBankReport(
      params: RemitBankReportPagination):
      Observable<RemitBankReports> {
    return this.http
        .post<RemitBankReports>(this.remitApiUrl+`/staff/bankreports`, params);
  }
  listTaiwanMiddleBank():
      Observable<RemitTaiwanMiddleBank[]> {
    return this.http
        .get<{body: RemitTaiwanMiddleBank[]}>(
            this.remitApiUrl+`/staff/taiwan-banks`).pipe(map((v)=>{
          return v.body;
        }));
  }
  getBankReport(
      id: string,
  ):
      Observable<RemitBankReport> {
    return this.http
        .get<{body: RemitBankReport}>(
            this.remitApiUrl+`/staff/bankreport/${id}`).
        pipe(map((v)=>{
          return v.body;
        }));
  }
  private markGroupReportDownload(
      id: string,
  ):
      Observable<RemitApiResponse> {
    return this.http
        .put<RemitApiResponse>(
            this.remitApiUrl+`/staff/bankreport/${id}/download`,
            null,
        );
  }

  exportBankReport(id:string):Observable<RemitApiResponse> {
    return this.getBankReport(id).pipe(
        switchMap((res)=>{
          return combineLatest([
            this.invoiceService.queryOrders({
              bankReportId: id,
              pageNumber: 0,
              pageSize: 10000,
            }),
            of(res.taiwanMiddleBankId),
          ]);
        }),
        switchMap( ([res, bank]: [RemitInvoices, string])=>{
          return this.exportReportInvoices(res, bank);
        }),
        switchMap(()=>{
          return this.markGroupReportDownload(id);
        }),
    );
  }

  exportReportInvoices(invoices: RemitInvoices, format: string):Observable<RemitApiResponse> {
    return from(this.exportPdfPoa(invoices)).pipe(
        map(() => [invoices, format] as [RemitInvoices, string]),
        tap(([res, bank] : [RemitInvoices, string])=>{
          if (bank != 'taichungBank') {
            return;
          }
          const formatted = res.body.map((v)=>{
            return remitInvoiceToTaichungFormat(v);
          });
          const aoa = this.arrayOfObjectsToArrayOfArrays(formatted);
          this._writeToXls(
              aoa, 'page1',
              `taichung${generateFileDateName()}.xls`,
          );
        }),
        tap(([res, bank])=>{
          if (bank != 'briTaipei') {
            return;
          }
          const formatted = res.body.map((v)=>{
            const format =remitInvoiceToBRIReportFormat(v);
            return format;
          });
          const aoa = this.arrayOfObjectsToArrayOfArrays(formatted);
          this._writeToXls(
              aoa, 'page1',
              `bri${generateFileDateName()}.xls`,
          );
        }),
        tap(([res, bank])=>{
          if (bank != 'sinopac') {
            return;
          }

          const formatted = res.body.map((v, i)=>{
            const format =remitInvoiceToSinopacFormat(i, v);
            return format;
          });
          const aoa = this.arrayOfObjectsToArrayOfArrays(formatted);
          this._writeToXls(
              aoa, 'page1',
              `sinopac${generateFileDateName()}.xls`,
          );
        }),
        tap(([res, bank])=>{
          if (bank != 'shinkongBank') {
            return;
          }
          const formatted = res.body.map((v, i)=>{
            const format =remitInvoiceToShinkongReportFormat(i, v);
            return format;
          });
          const aoa = this.arrayOfObjectsToArrayOfArrays(formatted);
          this._writeToXls(
              aoa, 'page1',
              `shinkong${generateFileDateName()}.xls`,
          );
          convertToTxtFile(formatted);
        }),
        switchMap(([res])=>{
          return this.markDownloaded(res);
        }),

    );
  }

  private markDownloaded(invoices: RemitInvoices): Observable<RemitApiResponse> {
    const ids = _.uniq( invoices.body.map((v)=>v.bankReportId)).
        map((v)=>{
          if (!v) return EMPTY;
          return this.markGroupReportDownload(v);
        });
    return merge(...ids);
  }


  async exportPdfPoa(ri: RemitInvoices) {
    const doc = new jsPDF('p', 'mm', 'a4');
    const pageWidth = doc.internal.pageSize.getWidth();

    const font = await this.fontResolver.resolve();
    const companyStamp = await this.convertToBase64('assets/solfftczygcp-min.png');
    const supervisorStamp = await this.convertToBase64('assets/whxqbxdvpmcp-min.png');
    doc.addFileToVFS('msjh-normal.ttf', font);
    doc.addFont('msjh-normal.ttf', 'msjh', 'normal');

    function checkbox(x: number, y: number, length: number, width: number) {
      doc.rect(x, y, length, width);
      doc.line(x, y, x + length, y + width);
      doc.line(x, y + length, x + width, y);
    }


    for (let index = 0; index < ri.body.length; index++) {
      const invoice = ri.body[index];
      const padding = 6;
      const rowSize = 6;
      const startsFrom = padding;

      const customerName = invoice.sender?.name ?? '';

      const titleText = '外籍勞工薪資結匯申報委託書';
      const subtitle1 = 'Power of Attorney for Foreign Workers on';
      const subtitle2 = 'Declaration of Foreign Exchange Settlement Related to Salaries';
      const firstParagraph = `委託人 ${customerName} 為合法入境工作領有外僑居留證之外籍勞工， 因無法至指定銀行辦理結匯， 茲委託(請擇一勾選填寫)`;
      const secondParagraph = `${customerName}, hereinafter as the consignor, is a legitimate foreign worker immigrated into Taiwan bearing the valid Alien Resident Certificate. I am not available to settle the foreign exchange remittance at an authorized bank personally, thereby appoint the on my behalf to deal the foreign exchange settlement related to my salaries through the authorized bank.\n(Please check the proper item and fill in the necessary information.) `;
      const checkBoxText1Zh = '代為經由指定銀行將委託人之薪資結匯匯至委託人指定之國外帳戶 (名稱:Sunrich Enterprise Co. Ltd,帳號766003602) 後， 再交付受款人。';
      const checkBoxText1En = 'The fund must be remitted to the consignor\'s appointed foreign account (Name: Sunrich Enterprise Co. Ltd, Account: 766003602) and deliver to my appointed recipients afterward.';
      const checkBoxText2Zh = '代為經由指定銀行將委託人之薪資結匯匯至受款人。';
      const checkBoxText2En = 'The remmitance must be delivered directly to the data of my appointed recipients. ';


      const recipient = ['受款人', 'Recipient', invoice.beneficiaryName];
      const recepientBank = ['受款行', 'Recipient\'s Bank', invoice.bankName ?? ''];
      const recepientAccount = ['受款人帳號', 'Recipient\'s Account No', invoice.beneficiaryAccountNumber];
      const recipientTelephone = ['聯絡電話', 'Telephone', ''];
      const recipientNationality = ['國籍', 'Nationality', 'Indonesia'];
      const recipientAddress = ['受款人地址', 'Recipient\'s Address', ''];
      const transactionNo = ['交易編號', 'Trx No.', invoice.transactionId];

      const consignor = ['委託人', 'Consignor', customerName];
      const consignorNationality = ['國籍', 'Nationality', 'Indonesia'];
      const consignorTelephone = ['在台聯絡電話', 'Telephone', invoice.sender?.phoneNumber ?? ''];
      const ammountRemitted = ['委託結匯金額', 'Amount Remitted', invoice.amountNTD];
      const arcNo = ['外僑居留證號碼', 'Alien Resident Certificate No.', invoice.sender?.arcNo ?? ''];

      let dateOfExpiryText = convertRFC3339ToYYYYMMDDTaiwanSlashed(invoice.sender?.arcExpiredDate ?? '');

      if (invoice.sender?.arcType == 'permanent') {
        dateOfExpiryText = '永久居留證';
      }
      const dateOfExpiry = [
        '外僑居留證有效期限',
        'Date of Expiry',
        dateOfExpiryText,
      ];


      const consignee = ['受託人', 'Consignee', '同合順企業股份有限公司', 'Sunrich Enterprise Co. Ltd.'];
      const dongbian = ['統一編號', 'Uniform No.', '27960810'];
      const consigneePhone = ['聯絡電話', 'Telephone', '02-2553-5058'];
      const consigneeAddress = ['地址', 'Address', '台北市大同區寧夏路84號8樓'];


      const afterTableText = '委託人已知悉並同意指定銀行依個人資料保護法對其蒐集、處理及利用個人資料，並自行至指定網站(仲介公司公告之網址)閱讀瞭解『個人資料保護法法定告知事項』，並授權受託人使用本人之電子簽名於此委託交易上。';


      const signatureImage = await this.convertToBase64(invoice.sender?.userSignatureImage ?? '');
      const arcImage = await this.convertToBase64(invoice.sender?.arcFrontImage ?? '');


      const consignorSignatureLabel = '委託人 Consignor: ';
      const signatureLabel = '（簽章）Signature';
      const consigneeSignatureLabel = '受託人 Consignee: ';

      const consigneeName = '同合順企業股份有限公司';


      const chineseDate = `中華民國 ${formatTaiwanDate(invoice.paidAt ?? '')}`;

      const englishDate = formatShortDate(invoice.paidAt ?? '');


      doc.setFontSize(12);
      doc.setFont('msjh');
      doc.text(titleText, pageWidth/2, startsFrom, {align: 'center'});
      doc.setFont('Times-Roman');
      doc.text(
          subtitle1,
          pageWidth/2, startsFrom + (rowSize ),
          {align: 'center'},
      );
      doc.text(
          subtitle2,
          pageWidth/2, startsFrom + (rowSize * 2),
          {align: 'center'},
      );

      doc.setFontSize(10);
      doc.setFont('msjh');
      const firstParagraphX = startsFrom + (rowSize * 4);
      const firstParagraphLines = doc.splitTextToSize(firstParagraph, 200);
      doc.text(firstParagraphLines, padding, firstParagraphX);
      const firstPHeight = firstParagraphLines.length * 4;

      const secondParagraphX = firstParagraphX + firstPHeight;
      const secondParagraphLines = doc.splitTextToSize(secondParagraph, 200);
      doc.text(secondParagraphLines, padding, secondParagraphX);
      const secondPHeight = secondParagraphLines.length * 4;

      const firstCheckBoxX = secondParagraphX + secondPHeight + 4;
      const firstCheckBoxLines = doc.splitTextToSize(checkBoxText1Zh, 200);
      checkbox(padding, firstCheckBoxX -2, 2, 2);
      doc.text(firstCheckBoxLines, padding + 5, firstCheckBoxX);
      const firstCheckBoxHeight = firstCheckBoxLines.length * 4;

      const firstCheckBoxEnglishX = firstCheckBoxX + firstCheckBoxHeight;
      const firstCheckBoxEnglishLines = doc.splitTextToSize(checkBoxText1En, 200);
      doc.text(firstCheckBoxEnglishLines, padding + 5, firstCheckBoxEnglishX);
      const firstCheckBoxEnglishHeight = firstCheckBoxEnglishLines.length * 4;

      const secondCheckBoxX = firstCheckBoxEnglishX + firstCheckBoxEnglishHeight + 2;
      const secondCheckBoxLines = doc.splitTextToSize(checkBoxText2Zh, 200);
      doc.rect(padding, secondCheckBoxX -2, 2, 2);
      doc.text(secondCheckBoxLines, padding + 5, secondCheckBoxX);
      const secondCheckBoxHeight = secondCheckBoxLines.length * 4;

      const secondCheckBoxEnglishX = secondCheckBoxX + secondCheckBoxHeight;
      const secondCheckBoxEnglishLines = doc.splitTextToSize(checkBoxText2En, 200);
      doc.text(secondCheckBoxEnglishLines, padding + 5, secondCheckBoxEnglishX);
      const secondCheckBoxEnglishHeight = secondCheckBoxEnglishLines.length * 4;

      const tableA = secondCheckBoxX + secondCheckBoxEnglishHeight + 6;
      const colonSpacing = 48;
      const paddingCol2 = padding + 120;
      const col2colonSpacing = 24;

      const col1Width = 120;
      const col2Width = 75;

      createColumn(recipient, padding, tableA, colonSpacing, col1Width);
      createColumn(recepientBank, padding, tableA + 12, colonSpacing, col1Width);
      createColumn(recepientAccount, padding, tableA + 12 * 2, colonSpacing, col1Width);
      createColumn(recipientTelephone, paddingCol2, tableA, col2colonSpacing, col2Width);
      createColumn(recipientNationality, paddingCol2, tableA + 12, col2colonSpacing, col2Width);
      createColumn(transactionNo, paddingCol2, tableA + 12 * 2, col2colonSpacing, col2Width);
      createColumn(recipientAddress, padding, tableA + 12 * 3, colonSpacing, col1Width+col2Width);


      const tableB = tableA + 12*4 + 2;

      createColumn(consignor, padding, tableB, colonSpacing, col1Width);
      createColumn(consignorNationality, padding, tableB + 12, colonSpacing, col1Width);
      createColumn(consignorTelephone, padding, tableB + 12 * 2, colonSpacing, col1Width);
      const tableBCol2Spacing = col2colonSpacing + 18;
      createColumn(ammountRemitted, paddingCol2, tableB, tableBCol2Spacing, col2Width);
      createColumn(arcNo, paddingCol2, tableB + 12, tableBCol2Spacing, col2Width);
      createColumn(dateOfExpiry, paddingCol2, tableB + 12 * 2, tableBCol2Spacing, col2Width);


      const tableC = tableB + 12*3 + 2;

      createColumn(consignee, padding, tableC, colonSpacing, col1Width);
      createColumn(dongbian, padding, tableC + 12, colonSpacing, col1Width);
      createColumn(consigneePhone, paddingCol2, tableC, col2colonSpacing, col2Width);
      createColumn(consigneeAddress, paddingCol2, tableC + 12, col2colonSpacing, col2Width);

      doc.setFontSize(8);


      const afterBoxTextX = tableC + 12*2 + 6;
      const afterTableTextLines = doc.splitTextToSize(afterTableText, 195);
      const afterBoxTextHeight = afterBoxTextX + afterTableTextLines.length * 4;

      const signatureRowX = afterBoxTextHeight;

      // STAMP IMAGE
      doc.addImage(supervisorStamp, 'PNG', padding + 135, signatureRowX - 3, 14, 14);
      doc.addImage(companyStamp, 'PNG', padding + 166, signatureRowX - 15, 30, 30);
      // STAMP IMAGE


      doc.text(afterTableTextLines, padding, afterBoxTextX);


      doc.text(consignorSignatureLabel, padding, signatureRowX + 9);
      doc.addImage(signatureImage, 'PNG', padding + 32, signatureRowX, 24, 18);
      // doc.rect(padding + 32, signatureRowX, 24, 18); // debug rect only
      doc.text(signatureLabel, padding + 64, signatureRowX + 9);

      doc.text(consigneeSignatureLabel, padding + 100, signatureRowX + 9);
      doc.text(consigneeName, padding + 127, signatureRowX + 9);
      doc.text(signatureLabel, padding + 160, signatureRowX + 9);


      const dateAndARCRow = signatureRowX + 28;

      doc.text(chineseDate, padding, dateAndARCRow + 3);
      doc.addImage(arcImage, 'PNG', padding + 52, dateAndARCRow - 10, 90, 56.25);
      // doc.rect(padding + 52, dateAndARCRow - 10, 90, 56.25); // debug rect only
      doc.text(englishDate, padding + 150, dateAndARCRow + 3);


      if (ri.body.length - 1 != index) {
        doc.addPage();
      }
    }


    doc.save('invoice'+(new Date()).toISOString()+'.pdf');

    function createColumn(labelValue: string[], padding: number, tablePosX: number, colonSpacing: number, width: number) {
      doc.rect(padding, tablePosX, width, 12);
      doc.text(labelValue[0], padding + 2, tablePosX + 5);
      doc.setFontSize(8);
      doc.text(labelValue[1], padding + 2, tablePosX + 9);
      doc.setFontSize(10);
      doc.text(':', padding + colonSpacing, tablePosX + 5);
      doc.text(':', padding + colonSpacing, tablePosX + 9);
      // if value is only a single value
      if (labelValue.length == 3) {
        doc.text(labelValue[2], padding + colonSpacing + 4, tablePosX + 7);
      }
      // if have english and chinese value
      if (labelValue.length == 4) {
        doc.text(labelValue[2], padding + colonSpacing + 4, tablePosX + 5);
        doc.setFontSize(8);
        doc.text(labelValue[3], padding + colonSpacing + 4, tablePosX + 9);
        doc.setFontSize(10);
      }
    }
  }
  private async convertToBase64(url: string): Promise<string> {
    if (this.base64Cache[url]) {
      return this.base64Cache[url];
    }


    const res = await fetch(url);
    const buffer = await res.arrayBuffer();
    let binary = '';
    const bytes = new Uint8Array(buffer);
    for (let i = 0, len = bytes.byteLength; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    const base64 = window.btoa(binary);

    this.base64Cache[url] = base64;

    return base64;
  }

  private arrayOfObjectsToArrayOfArrays(arrayOfObjects: object[]): (string|number)[][] {
    const headerRow = Object.keys(arrayOfObjects[0]);
    const dataRows = arrayOfObjects.map((obj) => Object.values(obj));
    return [headerRow, ...dataRows];
  }

  private _writeToXls(
      aoa: (string|number)[][],
      sheetName: string,
      fileName: string) {
    import('xlsx').then((XLSX) => {
      const wb = XLSX.utils.book_new();
      const ws = XLSX.utils.aoa_to_sheet(aoa);
      XLSX.utils.book_append_sheet(wb, ws, sheetName);

      XLSX.writeFile(
          wb,
          fileName,
          {
            bookType: 'biff8',
          },
      );
    });
  }
}


export function remitInvoiceToShinkongReportFormat(i: number, v: RemitInvoice):
RemitBankReportShinkongFormat {
  let arcExpiredDate = rfc3339ToYYYYMMDDTaiwanTime(v.sender?.arcExpiredDate ?? '');

  if (v.sender?.arcType == 'permanent') {
    arcExpiredDate = '99981231';
  }

  return {
    no: (i + 1).toString(),
    nameOfForeigner: v.sender?.name ?? '',
    nationalities: 'ID',
    arcNo: v.sender?.arcNo ?? '',
    arcExpiryDate: arcExpiredDate,
    telNoTaiwan: convertToTaiwanLocalFormat(v.sender?.phoneNumber ?? ''),
    twdDollars: parseInt(v.amountNTD),
    equivalentIDR: parseInt(v.foreignAmount),
    beneficiaryBank: v.bankName,
    beneficiaryAccountNo: v.beneficiaryAccountNumber,
    beneficiaryAccountName: v.beneficiaryName,
    senderBirthday: rfc3339ToYYYYMMDDTaiwanTime(v.sender?.dateOfBirth ?? ''),
    beneficiaryBirthday: '',
    beneficiaryNationalities: 'ID',
    remittanceDate: rfc3339ToYYYYMMDDTaiwanTime(v.reportedAt ?? ''),
    taiwanCompanyIdNo: '27960810',
  };
}

export function remitInvoiceToSinopacFormat(i: number, invoice: RemitInvoice):
RemitBankReportSinopacFormat {
  return {
    no: (i + 1).toString(),
    nameOfForeigner: invoice.sender?.name ?? '',
    nationalities: 'ID',
    arcNo: invoice.sender?.arcNo ?? '',
    arcExpiryDate: convertRFC3339ToYYYYMMDDTaiwanSlashed(invoice.sender?.arcExpiredDate?? ''),
    taiwanPhoneNo: convertToTaiwanLocalFormat(invoice.sender?.phoneNumber ?? ''),
    amountOfMandatedSettlement: parseInt(invoice.amountNTD),
    equivalentUSD: '',
    beneficiaryBank: invoice.bankName,
    beneficiaryAccountNo: invoice.beneficiaryAccountNumber,
    beneficiaryAccountName: invoice.beneficiaryName,
    beneficiaryPhoneNo: '',
    remitterDateOfBirth: convertRFC3339ToYYYYMMDDTaiwanSlashed(invoice.sender?.dateOfBirth ?? ''),
    remitterGender: invoice.sender?.sex ?? '',
    beneficiaryNationalities: 'ID',
    beneficiaryDateOfBirth: '',
    beneficiaryGender: '',
  };
}

export function remitInvoiceToBRIReportFormat(invoice: RemitInvoice):
RemitBankReportBRIFormat {
  return {
    委託人居留證號碼: invoice.sender?.arcNo ?? '',
    居留證有效期限: convertRFC3339ToYYYYMMDDTaiwanSlashed(invoice.sender?.arcExpiredDate?? ''),
    委託人生日: convertRFC3339ToYYYYMMDDTaiwanSlashed(invoice.sender?.dateOfBirth?? ''),
    委託人在臺電話: convertToTaiwanLocalFormat(invoice.sender?.phoneNumber ?? ''),
    委託結匯金額: parseInt(invoice.amountNTD),
    委託人姓名: invoice.sender?.name?? '',
    委託人國籍: 'ID',
    受款人姓名: invoice.beneficiaryName,
    受款人生日: convertDateToSlashedFormat(invoice.beneficiaryDateOfBirth ?? ''),
    受款人國籍: 'ID',
    受款國家: 'ID',
    受款銀行: invoice.bankName,
  };
}

function convertDateToSlashedFormat(dateString: string): string {
  if (!dateString) return '';
  const date = parseISO(dateString);
  return format(date, 'yyyy/MM/dd');
}

function remitInvoiceToTaichungFormat(invoice: RemitInvoice):
RemitBankReportTaichungFormat {
  return {
    委託人: invoice.sender?.name?? '',
    國籍: '印尼',
    外僑居留號碼: invoice.sender?.arcNo?? '',
    外僑居留證有效期限: convertRFC3339ToYYYYMMDDTaiwanSlashed(invoice.sender?.arcExpiredDate?? ''),
    委託人在臺居所電話: convertToTaiwanLocalFormat(invoice.sender?.phoneNumber ?? ''),
    委託結匯金額: invoice.amountNTD,
    受款人姓名: invoice.beneficiaryName,
    受款人國籍: '印尼',
    註冊日期: convertRFC3339ToYYYYMMDDTaiwanSlashed(invoice.sender?.createdAt ?? ''),
    最後資料修改日期: convertRFC3339ToYYYYMMDDTaiwanSlashed(invoice.sender?.updatedAt ?? ''),
    收據號碼: invoice.transactionId,
    匯款人生日: convertRFC3339ToYYYYMMDDTaiwanSlashed(invoice.sender?.dateOfBirth ?? ''),
    收款人銀行: invoice.bankName,
    收款人銀行帳號: invoice.beneficiaryAccountNumber,
  };
}

function padString(value: string, width: number, paddingChar: string): string {
  return value.padEnd(width, paddingChar).slice(0, width);
}

function padStartString(
    value: string, width: number, paddingChar: string): string {
  return value.padStart(width, paddingChar).slice(0, width);
}

function convertToTxtFile(data: RemitBankReportShinkongFormat[]): void {
  const txtContent = data.map((item) => {
    return (
      padString(item.remittanceDate, 8, ' ') +
      padString(item.taiwanCompanyIdNo, 8, ' ') +
      padString(item.nameOfForeigner, 70, ' ') +
      padString(item.nationalities, 2, ' ') +
      padString(item.arcNo, 10, ' ') +
      padString(item.arcExpiryDate, 8, ' ') +
      padString(item.telNoTaiwan, 20, ' ') +
      padStartString(item.twdDollars.toString(), 15, '0') +
      padStartString(item.equivalentIDR.toString(), 15, '0') +
      padString(item.beneficiaryBank, 70, ' ')+
      padString(item.beneficiaryAccountNo, 35, ' ')+
      padString(item.beneficiaryAccountName, 70, ' ')+
      padString(item.senderBirthday, 8, ' ')+
      padString(item.beneficiaryBirthday, 8, ' ')+
      padString(item.beneficiaryNationalities, 2, ' ')
    );
  });

  const txtFileContent = txtContent.join('\n');

  try {
    const blob = new Blob([txtFileContent], {type: 'text/plain;charset=utf-8'});

    saveAs(blob, `shinkong${generateFileDateName()}.txt`);
    console.log('File successfully created: output.txt');
  } catch (err) {
    console.error('Error writing the file:', err);
  }
}


function rfc3339ToYYYYMMDDTaiwanTime(rfc3339String: string): string {
  const date = new Date(rfc3339String);

  const taiwanTime = new Date(date.getTime() + 8 * 60 * 60 * 1000);

  const year = taiwanTime.getUTCFullYear();
  const month = taiwanTime.
      getUTCMonth() + 1; // Months are zero-indexed, so add 1
  const day = taiwanTime.getUTCDate();

  // eslint-disable-next-line max-len
  const formattedDate = `${year}${month.toString().padStart(2, '0')}${day.toString().padStart(2, '0')}`;
  return formattedDate;
}

function convertRFC3339ToYYYYMMDDTaiwanSlashed(rfc3339Date: string): string {
  const date = parseISO(rfc3339Date);
  return format(date, 'yyyy/MM/dd');
}


function convertToTaiwanLocalFormat(internationalNumber: string): string {
  if (internationalNumber.startsWith('+886')) {
    return '0' + internationalNumber.substring(4);
  }
  return '';
}


function formatTaiwanDate(rfc3339: string): string {
  const date = new Date(rfc3339);
  const taiwanYear = date.getFullYear() - 1911;
  const month = date.getMonth() + 1; // JavaScript months are 0-11
  const day = date.getDate();
  return `${taiwanYear} 年 ${month} 月 ${day} 日`;
}

function formatShortDate(rfc3339: string): string {
  const date = new Date(rfc3339);
  const year = date.getFullYear().toString().substr(-2); // get last 2 digits of year
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `Date (YY/MM/DD): ${year} / ${month} / ${day}`;
}


function generateFileDateName(): string {
  const currentDate = new Date();
  const year = currentDate.getFullYear();
  const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
  const day = currentDate.getDate().toString().padStart(2, '0');

  const formattedDate = `${year}${month}${day}`;
  return formattedDate;
}
