import {HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi} from '@angular/common/http';
import {NgModule} from '@angular/core';


import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
  AuthHttpInterceptor,
  AuthService,
  FireAuthService,
} from '@indosuara/auth';

// eslint-disable-next-line @nx/enforce-module-boundaries
import {FirebaseStaffService, StaffService} from '@indosuara/superadmin';
import 'chartjs-adapter-date-fns';
import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {IndosuaraHideProdDirective} from './common/hide-prod.directive';
import {FieldErrorModule} from './custom-controls/field-error/field-error.module';
import {FirebaseCustomerProfileService} from './customer-service/customer-profile/customer-profile.firebase.service';
import {CustomerProfileService} from './customer-service/customer-profile/customer-profile.service';
import {HomeComponent} from './home/home.component';
import {LoginComponent} from './login/login.component';
import {MaterialModule} from './material.module';
import {NavigationMenuComponent} from './navigation-menu/navigation-menu.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';

import {FirebaseOrderService} from './sales/orders/order/order.firebase.service';
import {OrderService} from './sales/orders/order/order.service';

import {initializeApp, provideFirebaseApp} from '@angular/fire/app';
import {connectAuthEmulator, getAuth, provideAuth} from '@angular/fire/auth';
import {FLIGHT_BASE_URL} from '@indosuara/flight-api';
import {GOLDSHOP_BASE_URL} from '@indosuara/goldshop-api';
import {INDO_WORKER_LOAN_BASE_URL} from '@indosuara/indo-worker-loan';
import {
  INDOPOS_BASE_URL,
  INDOPOS_DRIVER_FEATURE_ENABLED,
  INDOPOS_INVENTORY_FEATURE_ENABLED,
} from '@indosuara/indopos-staff-config';
import {DEFAULT_ORDER_SHOP_CODE_FILTER, QINVENTORY_BASE_URL, REMIT_AUTO_ARC_BASE_URL, REMIT_BASE_URL} from '@indosuara/remit-api';
import 'chartjs-adapter-date-fns';

import {getFirestore, provideFirestore} from '@angular/fire/firestore';
import {getFunctions, provideFunctions} from '@angular/fire/functions';
import {MomentDateAdapter} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE} from '@angular/material/core';

const dateFormats = {
  parse: {
    dateInput: ['YYYY-MM-DD', 'YYYY/MM/DD', 'YYYYMMDD', 'YYMMDD'],
  },
  display: {
    dateInput: 'YYYY/MM/DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    PageNotFoundComponent,
    LoginComponent,
    NavigationMenuComponent,
    IndosuaraHideProdDirective,
  ],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    FieldErrorModule,
  ],
  providers: [
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      const auth = getAuth();
      auth.tenantId = environment.staffTenantId ?? 'employee-bml6n';
      if (environment.useEmulators) {
        connectAuthEmulator(auth, 'http://localhost:9099', {
          disableWarnings: true,
        });
      }
      return auth;
    }),
    provideFirestore(() => getFirestore()),
    provideFunctions(() => {
      const fn = getFunctions();
      fn.region = 'asia-east1';
      return fn;
    }),

    {
      provide: AuthService,
      useClass: FireAuthService,
    },
    {
      provide: OrderService,
      useClass: FirebaseOrderService,
    },
    {
      provide: CustomerProfileService,
      useClass: FirebaseCustomerProfileService,
    },
    {
      provide: StaffService,
      useClass: FirebaseStaffService,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHttpInterceptor,
      multi: true,
    },


    {
      provide: INDOPOS_DRIVER_FEATURE_ENABLED,
      useValue: true,
    },
    {
      provide: INDOPOS_BASE_URL,
      useValue: environment.indosuaraApiUrl,
    },
    {
      provide: INDO_WORKER_LOAN_BASE_URL,
      useValue: environment.loanDatabaseUrl,
    },
    {
      provide: REMIT_BASE_URL,
      useValue: environment.indosuaraApiUrl + '/remitz/api/v0',
    },
    {
      provide: QINVENTORY_BASE_URL,
      useValue: environment.indosuaraApiUrl + '/qinventory/api/v0/staff',
    },
    {
      provide: REMIT_AUTO_ARC_BASE_URL,
      useValue: environment.indosuaraApiUrl + '/arc-automation',
    },
    {
      provide: INDOPOS_INVENTORY_FEATURE_ENABLED,
      useValue: true,
    },
    {
      provide: FLIGHT_BASE_URL,
      useValue: environment.indosuaraApiUrl,
    },
    {
      provide: GOLDSHOP_BASE_URL,
      useValue: environment.indosuaraApiUrl,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    {
      provide: DEFAULT_ORDER_SHOP_CODE_FILTER,
      useValue: 'IS002',
    },
    {provide: MAT_DATE_FORMATS, useValue: dateFormats},
    provideHttpClient(withInterceptorsFromDi()),
  ]})

/**
 * Main Indosuara Staff UI app module
 */
export class AppModule {}

