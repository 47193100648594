import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import * as _ from 'lodash';
import {Observable, map, of} from 'rxjs';
import {REMIT_BASE_URL} from './config';
import {RemitApiResponse} from './kyc/user-verification.service';
import {RemitPublicImageUploaderService} from './remit-public-image-uploader.service';


export interface RemitShopItem {
  id: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
  version: string
  name: string
  description: string
  price: number
  capitalPrice?: number
  image: string
  vipDurationDays: number
  isDeleted: boolean
  shippingCost?: number;
  itemType?: string;
  digitalProductDetails?: DigitalProductDetails
  soldOut?:boolean;
  notes?: string
  available?:boolean;
  tags?: string[]
  barcodes?: string[]
  voucherCount?: number
  originalPrice?: number
  requiredFields?: RequiredFields[]
  shortDescription?: string
  sku?: string
  variant?: ShopItemVariant[]
}

export interface ShopItemVariant{
  name?: string
  sku?: string
  soldOut?: boolean
}

export interface RequiredFields{
  name: string
  label: string
  type: string
  isRequired: boolean
  minimumLength?: number
  maximumLength?: number
}

export interface DigitalProductDetails{
  id?: string | null
}

export interface RemitShopItemCreateForm{
  name: string
  description: string
  price: number
  image: string
  vipDurationDays: number
  shippingCost: number;
  itemType: string;
  digitalProductDetails?: DigitalProductDetails | null
  soldOut?: boolean
  tags?: string[]
  voucherCount?: number
  originalPrice?: number;
  shortDescription?: string
  sku?: string
  variant?: ShopItemVariant[]
  notes?: string
  capitalPrice?: number
  barcodes?: string[]
}

interface RemitShopItemCreateWriteModel{
  name: string
  description: string
  price: number
  image: string
  vipDurationDays: number
  shippingCost: number;
  itemType: string;
  digitalProductDetails?: DigitalProductDetails | null
  soldOut?: string
  tags?: string[]
  voucherCount?: number
  originalPrice?: number
  shortDescription?: string
  sku?: string
  variant?: ShopItemVariant[]
  notes?: string
  barcodes?: string[]
}

export interface ShopItemQueryParams{
  pageNumber?: number | null
  pageSize?: number | null
  name?: string | null
  search?: string | null
  itemType?: string | null
  isDeleted?: 'Y' | 'N' | null
}

export interface ShopOrderItem {
  itemId: string;
  itemType: string;
  bundlingId: string;
  quantity: number;
  orderPrice: number;
  name: string;
  digitalItemDetail?: DigitalItemDetail;
  transactionStatus: string;
  transactionStatusName?: string | null;
  transactionMessage?: string | null;
}

export interface DigitalItemDetail {
  refId: string;
  destinationNumber: string;
}

export interface ShopOrder {
  id: string;
  customerId: string;
  itemsView: ShopOrderItem[];
  name: string;
  phoneNumber: string;
  address: string;
  totalPrice: number;
  note: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  isDeleted: boolean;
  shippedAt?: string;
  shippedBy: string;
  shippingNumber: string;
  paidAt?: string;
  paidBy: string;
  refTradeNo: string;
  paymentCvsCode: string;
  paymentCvsCodeUrl: string;
  expiredAt?: string;
  orderStatusName?: string;
  orderStatus?: string;
  invoicedAt?: string;
  invoiceNumber?: string;
  invoiceMethod?: string;
  invoiceAmount?: number;
  paymentMethodName?: string;
  shopCode?: string;
  version: string;
}

export interface QueryResult<T>{
  body: T[]
  totalData: number
}

export interface BaseQueryParams{
  pageNumber?: number | null
  pageSize?: number | null
  sortBy?: string | null
  sortDirection?: number | null
  search?: string | null
}

export type ShopOrderQueryParams = BaseQueryParams & {
  orderStatus?: string | null
  isDeleted?: 'Y' | 'N' | null
  shopCode?: string | null
  userId?: string | null
  createdAtStart?: string | null
  createdAtEnd?: string | null
  shippedAtStart?: string | null
  shippedAtEnd?: string | null
  tags?: string[] | null
}

export type ShopItemTagQueryParams = BaseQueryParams

export interface ShopItemType {
  id: string
  name: string
}

export interface PrepaidProduct {
  productCode: string;
  productDescription: string;
  productNominal: string;
  productDetails: string;
  productPrice: number;
  productType: string;
  activePeriod: string;
  status: string;
  iconUrl: string;
  productCategory: string;
}

export type BundlingOrderAction = 'cancelled' |
'completed'| 'rejected' | 'returned'| 'einvoice' |
'undo-cancelled' | 'undo-completed' |
'undo-delete' | 'undo-rejected' | 'undo-shipped';

export type FeatureStatus = 'hide' | 'beta' | 'prod';


export interface ShopOrderItemSummary{
  body: ShopOrderItemSummaryItem[]
  totalData: number
  totalGross: number
  totalNet: number
  totalCapital: number
}

export interface ShopOrderItemSummaryItem{
  itemId: string
  itemName: string
  totalQuantity: string
  notes: number
  capitalPrice: number
  price: number
  sku: string
  totalGross: number
  totalNet: number
}

interface OrderItemSummaryQueryParams {
  pageNumber: number;
  pageSize: number ;
  shopCode: string | null;
  orderStatus: string | null;
  paymentMethod?: number | null;
  createdAtStart?: string | null;
  createdAtEnd?: string | null;
  sortBy?: string | null;
  sortDirection?: string | null;
  sku? : string | null;
  itemName: string;
  tags: string[];
}

export interface RemitShopBranchCreateForm {
  name: string
  code: string
  staffIds: string[]
}
export interface RemitShopBranch {
  id: string
  name: string
  code: string
  staffIds: string[]
  isDeleted: boolean
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}
export interface ShopBranchQueryParams {
  pageNumber?: number | null
  pageSize?: number | null
  name?: string | null
  code?: string | null
  isDeleted?: boolean | null
}


export interface FeatureConfig {
  features: {
    bundlingHome: FeatureStatus;
    pay88Shop: FeatureStatus;
    shopHeimaoPay: FeatureStatus;
  }
  betaUids: string[];
}

export interface CategoryListing {
  categories?: Category[];
}

export interface Category {
  name: string;
  image: string;
  subCategories?: SubCategory[];
}

export interface SubCategory {
  name: string;
  image: string;
  items?: DiscoveryItem[];
}

export interface DiscoveryItem {
  id: string;
}


export interface ShopItemTagForm{
  name?: string
  description?: string
}

export interface ShopItemTag{
  id: string
  name: string
  description?: string
  createdAt: string
  createdBy: string
  updatedAt: string
  updatedBy: string
}

export interface RemitOrderCreation{
  customerId?: string
  items: RemitOrderItem[]
  note?: string
  address?: string
  paymentMethod?: number
  shopCode?: string
}

export interface RemitOrderItem{
  itemId: string
  quantity: number
  digitalItemDetail?: Map<string, string>
}


export interface ShopOrderSummary{
  date: string
  total: number
  count: number
  perPaymentMethod?: ShopOrderPerPayment[]
}

export interface ShopOrderPerPayment{
  id: number
  count: number
  total: number
  name?: string
}


@Injectable({
  providedIn: 'root',
})
export class RemitShopService {
  constructor(
    private http: HttpClient,
    private publicImageUploader: RemitPublicImageUploaderService,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
  ) { }

  createShopItem(data: Partial<RemitShopItemCreateForm>):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/bundling',
        this.buildUpdateWriteModel(data),
    );
  }

  updateShopItem(id: string, data: Partial<RemitShopItemCreateForm>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/bundling/${id}`,
        this.buildUpdateWriteModel(data),
    );
  }

  private buildUpdateWriteModel(data: Partial<RemitShopItemCreateForm>):
  Partial<RemitShopItemCreateWriteModel> {
    const writeModel: Partial<RemitShopItemCreateWriteModel> = {
      name: data.name,
      description: data.description,
      price: data.price,
      image: data.image,
      vipDurationDays: data.vipDurationDays,
      shippingCost: data.shippingCost,
      itemType: data.itemType,
      digitalProductDetails: data.digitalProductDetails,
      tags: data.tags,
      voucherCount: data.voucherCount,
      originalPrice: data.originalPrice,
      shortDescription: data.shortDescription,
      sku: data.sku,
      variant: _.cloneDeep(data.variant),
      notes: data.notes,
      barcodes: data.barcodes,
    };


    if (data.soldOut === true) {
      writeModel.soldOut = 'Y';
    }
    if (data.soldOut === false) {
      writeModel.soldOut = 'N';
    }


    return writeModel;
  }


  getShopItem(id: string): Observable<RemitShopItem> {
    return this.http.get<{body: RemitShopItem}>(
        this.remitApiUrl + `/staff/bundling/${id}`,
    ).pipe(
        map((v)=> v.body),
    );
  }

  queryShopItems(params: ShopItemQueryParams):
  Observable<QueryResult<RemitShopItem>> {
    return this.http.post<QueryResult<RemitShopItem>>(
        this.remitApiUrl + `/staff/bundlings`,
        params,
    );
  }

  deleteShopItem(id: string): Observable<RemitApiResponse> {
    return this.http.delete<RemitApiResponse>(
        this.remitApiUrl + `/staff/bundling/${id}`,
    );
  }

  getShopItemTypes(): Observable<ShopItemType[]> {
    return this.http.get<{body: ShopItemType[]}>(
        this.remitApiUrl + `/staff/item-types`,
    ).pipe(
        map((v)=> v.body),
    );
  }

  isBundlingFeatureEnabled(): Observable<boolean> {
    return this.http.get<{body: {isEnabled: boolean}}>(
        this.remitApiUrl + `/staff/bundling-feature/is-enabled`,
    ).pipe(
        map((v)=> v.body.isEnabled),
    );
  }

  setBundlingFeatureOn(): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/bundling-feature/on`, {},
    );
  }

  setBundlingFeatureOff(): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/bundling-feature/off`, {},
    );
  }

  getOrder(id: string): Observable<ShopOrder> {
    return this.http.get<{body:ShopOrder}>(
        this.remitApiUrl + `/staff/order/${id}`,
    ).pipe(
        map((v)=> v.body),
    );
  }

  queryOrders(params: ShopOrderQueryParams):
  Observable<QueryResult<ShopOrder>> {
    return this.http.post<QueryResult<ShopOrder>>(
        this.remitApiUrl + `/staff/orders-view`,
        params,
    );
  }


  queryOrdersSummary(params: ShopOrderQueryParams):
  Observable<QueryResult<ShopOrderSummary>> {
    return this.http.post<QueryResult<ShopOrderSummary>>(
        this.remitApiUrl + `/staff/orders-summary`,
        params,
    );
  }

  queryOrderItemsSummary(params: Partial<OrderItemSummaryQueryParams>):
  Observable<ShopOrderItemSummary> {
    return this.http.post<ShopOrderItemSummary>(
        this.remitApiUrl + `/staff/orders-item-summary`,
        params,
    );
  }

  setOrderShipped(id: string, data: {
    shippingNumber: string,
  }):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${id}/shipped`,
        data,
    );
  }

  queryPrepaidProducts():Observable<PrepaidProduct[]> {
    return this.http.post<QueryResult<PrepaidProduct>>(
        this.remitApiUrl+`/staff/prepaid-products`,
        {},
    ).pipe(map((v)=>v.body));
  }

  createOrder(data: Partial<RemitOrderCreation>):
  Observable<{
    body: ShopOrder
    message: string
    id: string
  }> {
    return this.http.post<{
      body: ShopOrder
      message: string
      id: string
    }>(
        this.remitApiUrl + `/staff/order`,
        data,
    );
  }

  updateOrder(id: string, data: Partial< {
    address: string,
    note: string,
  }>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${id}`,
        data,
    );
  }

  setOrder(id: string, action: BundlingOrderAction):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${id}/${action}`,
        {},
    );
  }

  processPrepaidTransaction(orderId: string): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${orderId}/top-up`,
        {},
    );
  }

  openOrderInvoice(id: string):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${id}/einvoice`,
        {},
    );
  }

  deleteOrder(id: string):
  Observable<RemitApiResponse> {
    return this.http.delete<RemitApiResponse>(
        this.remitApiUrl + `/staff/order/${id}`,
    );
  }

  getFeatureConfig(): Observable<FeatureConfig | undefined> {
    return this.http.get<{body?: FeatureConfig}>(
        this.remitApiUrl + `/staff/shop-features`,
    ).pipe(map((v)=>v.body));
  }

  getFeatureStates(): Observable<{id:FeatureStatus, name:string}[]> {
    return of([
      {id: 'hide', name: 'Hide - Not shown'},
      {id: 'beta', name: 'Beta - Shown to selected users only'},
      {id: 'prod', name: 'Production - Shown to all'},
    ]);
  }

  setFeatureStatus(data: Partial<FeatureConfig>): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/shop-features`,
        data,
    );
  }

  uploadPublicImage(file: File): Observable<string> {
    return this.publicImageUploader.uploadPublicImage(file);
  }

  updateCategoryListing(data: Partial<CategoryListing>):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/category`,
        data,
    );
  }

  getCategoryListing(): Observable<CategoryListing> {
    return this.http.
        get<{body: CategoryListing}>(
            this.remitApiUrl + `/staff/categories`)
        .pipe(map((v)=> v.body));
  }

  changeDestinationNumber(orderId: string, index: number,
      data: any): Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl +
        `/staff/order/${orderId}/item/${index}`+
        `/destination-number`,
        data,
    );
  }

  changeOrderItem(orderId: string, index: number,
      itemId:string, data:any): Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl +
        `/staff/order/${orderId}/item/${index}`+
        `/change-order-item`,
        {
          itemId: itemId,
          ...data,
        },
    );
  }

  refreshPrepaidTransactionStatus(orderId: string, orderItemIndex: number):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl +
        `/staff/order/${orderId}/item/${orderItemIndex}`+
        `/refresh`,
        {},
    );
  }

  prepaidTransactionTrigger(orderId: string, orderItemIndex: number):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl +
        `/staff/order/${orderId}/item/${orderItemIndex}`+
        `/top-up`,
        {},
    );
  }

  createNewItemTag(tag: ShopItemTagForm): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/item-tag',
        tag,
    );
  }

  getItemTag(id: string): Observable<ShopItemTag> {
    return this.http.get<{body: ShopItemTag}>(
        this.remitApiUrl + '/staff/item-tag/' + id,
    ).pipe(map((v)=> v.body));
  }

  updateItemTag(id: string, tag: ShopItemTagForm):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + '/staff/item-tag/' + id,
        tag,
    );
  }

  deleteItemTag(id: string): Observable<RemitApiResponse> {
    return this.http.delete<RemitApiResponse>(
        this.remitApiUrl + '/staff/item-tag/' + id,
    );
  }

  queryItemTags(params: ShopItemTagQueryParams):
  Observable<QueryResult<ShopItemTag>> {
    return this.http.post<QueryResult<ShopItemTag>>(
        this.remitApiUrl + '/staff/item-tags',
        params,
    );
  }

  createShopBranch(data: Partial<RemitShopBranch>):
  Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/shop-branch',
        data,
    );
  }

  updateShopBranch(id: string, data: Partial<RemitShopBranch>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl + '/staff/shop-branch/' + id,
        data,
    );
  }
  getShopBranch(id: string): Observable<RemitShopBranch> {
    return this.http.get<{body: RemitShopBranch}>(
        this.remitApiUrl + '/staff/shop-branch/' + id,
    ).pipe(map((v)=> v.body));
  }

  queryShopBranch(params: ShopBranchQueryParams):
  Observable<QueryResult<RemitShopBranch>> {
    return this.http.post<QueryResult<RemitShopBranch>>(
        this.remitApiUrl + '/staff/shop-branches',
        params,
    );
  }
  deleteShopBranch(id: string): Observable<RemitApiResponse> {
    return this.http.delete<RemitApiResponse>(
        this.remitApiUrl + '/staff/shop-branch/' + id,
    );
  }

  refreshOrderView(): Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + '/staff/orders/populate/orders-view',
        null,
    );
  }

  orderStatus(): Observable<Record<string, string>> {
    return of({
      '100': 'Waiting',
      '200': 'Paid/Pending',
      '300': 'Processed',
      '400': 'Completed',
      '500': 'Expired',
      '600': 'Problem',
      '700': 'Cancelled',
      '701': 'Rejected',
      '702': 'Returned',
    });
  }

  paymentMethod(): Observable<{id: number; name: string}[]> {
    return of([
      {id: 0, name: 'CVS 超商店代嗎'},
      {id: 1, name: 'Cash 現金'},
      {id: 2, name: 'LINE Pay'},
      {id: 3, name: 'CC 刷卡'},
    ]);
  }
}
