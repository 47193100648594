<div class="filters">
  <mat-form-field appearance="outline" class="name">
    <mat-label>Name</mat-label>
    <input matInput [formControl]="params.controls.name">
  </mat-form-field>

  <mat-form-field appearance="outline" class="item-type">
    <mat-label>Item Type</mat-label>
    <mat-select [formControl]="params.controls.itemType">
      <mat-option  [value]="''">
        All
      </mat-option>
      <mat-option *ngFor="let itemType of itemTypes$ | async" [value]="itemType.id">
        <div class="chip" [ngClass]="itemType.id">
          {{itemType.name}}
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="outline">
    <mat-label>Category Tags</mat-label>
    <mat-select [formControl]="params.controls.tags" multiple>
      <mat-option *ngFor="let tag of itemTags$ | async" [value]="tag.id">
        {{tag.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<div *ngIf="products$ | async as products">
  <div class="table-container">
    <table>
      <tr>
        <th>Image</th>
        <th>Name</th>
        <th>Price</th>
        <th>Action</th>
      </tr>
      <tr *ngFor="let item of products.body">
        <td>
          <div class="image-container">
            <img [src]="item.image">
          </div>
        </td>
        <td>{{item.name}}</td>
        <td>{{item.price}}</td>
        <td>
          <button mat-stroked-button (click)="select(item.id)">
            Select
          </button>
        </td>
      </tr>
    </table>
  </div>
  <mat-paginator
  [length]="products.totalData"
  [pageSize]="params.value.pageSize"
  [pageIndex]="params.value.pageNumber"
  (page)="onPaginateChange($event)"
  ></mat-paginator>
</div>
