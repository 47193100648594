import {Observable, map, switchMap} from 'rxjs';

import {HttpClient} from '@angular/common/http';
import {Inject, Injectable} from '@angular/core';
import {REMIT_AUTO_ARC_BASE_URL, REMIT_BASE_URL} from '../config';
import {PaginationParams} from '../remit-invoice.service';


export interface KycListParams {
  pageSize?: number
  pageNumber?: number
  status?: KycVerificationStatus[] | null
  arcNo?: string | null
  name?: string | null
  phoneNumber?: string | null
  isUpdateMember?: 'Y' | 'N' | '' | null
}

export interface KycForms{
  body: KycForm[];
  totalData: number;
}

export type KycVerificationStatus = 'inReview' |
'needRevision' | 'verified' | 'rejected' | ''

export type KycUserGender = 'male' | 'female' | ''

export type KycArcType = 'student' | 'work' | 'whiteCollar'
| 'permanent' | 'missionaries' |
'marriage' | 'other'


export type KycProblemType = 'arcFrontProblem' |
'arcBackProblem' | 'selfieImageProblem' | 'userSignatureProblem'

export type KycForm = KycBasicInfo & KycUpdatableInfo;


export interface KycBasicInfo{
  id: string;
  userId: string;
  phoneNumber: string;
  status: KycVerificationStatus;
  createdAt: string;
  createdBy: string;
  updatedAt: string;
  updatedBy: string;
  problems: KycProblemType[];
  problemMessage: string;
  isUpdateMember?: 'Y' | 'N' | '';
  parsedText?: string;
  ipAddress?: string;
  potentialPhoneNumber?: string[];
}

export interface KycUpdatableInfo {
  arcFrontImage: string;
  arcBackImage: string;
  selfieImage: string;
  name: string;
  userSignatureImage: string;
  arcNo: string;
  dateOfBirth: string;
  sex: KycUserGender;
  address: string;
  arcType: KycArcType;
  arcOld: string;
  arcExpiredDate?: string;
  arcIssueDate?: string;
  attachments?: string[];
  arcBarcodeNumber: string;
  nationality: string;
  institution: string;
  passportNumber: string;
}

export type KycUpdatableInfoKeys = keyof KycUpdatableInfo


export interface RemitApiResponse{
  message?: string;
  error?: string;
}

export interface KycRequestRevisionForm{
  problems: KycProblemType[];
  problemMessage: string;
}

export interface KycAcceptBlacklistRequest {
  blacklistReason: string;
}


export interface RemitRegisterSummary{
  registerDate: string;
  acceptCount: number;
}

export interface RemitRegisterSummaries{
  body: RemitRegisterSummary[]
  totalData: number;
}

export interface QueryRemitRegisterSummariesParams {
  pageSize?: number
  pageNumber?: number
  updatedAtStart?: string | null
  updatedAtEnd?: string | null
}


export interface KycComments{
  totalData: number
  body: KycComment[]
}

export interface KycComment {
  id: string
  aggregationId: string
  authorId: string
  isDeleted: 'Y' | 'N' | ''
  content: string
  createdAt: string
}


@Injectable({
  providedIn: 'root',
})
export class UserVerificationService {
  constructor(
    private http: HttpClient,
    @Inject(REMIT_BASE_URL) private remitApiUrl: string,
    @Inject(REMIT_AUTO_ARC_BASE_URL) private autoArcUrl: string,
  ) {}

  listRegisterSummaries(params: QueryRemitRegisterSummariesParams):
  Observable<RemitRegisterSummaries> {
    return this.http.post<RemitRegisterSummaries>(
        this.remitApiUrl + '/staff/app-register-summary',
        params,
    );
  }

  listKYCForms(params: KycListParams): Observable<KycForms> {
    return this.http.post<KycForms>(
        this.remitApiUrl + '/staff/kycs',
        params,
    );
  }

  getKYCFormDetails(id: string): Observable<KycForm> {
    return this.http.get<{body: KycForm}>(
        this.remitApiUrl + '/staff/kyc/' + id,
    ).pipe(map((v)=> v.body));
  }

  acceptUser(id: string): Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl+`/staff/kyc/${id}/approve`,
        {},
    );
  }

  acceptUserWithBlacklist(id: string, data: KycAcceptBlacklistRequest):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl+`/staff/kyc/${id}/approve-with-blacklist`,
        data,
    );
  }

  rejectUser(id: string): Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl+`/staff/kyc/${id}/reject`,
        {},
    );
  }

  requestRevision(
      id:string, data: KycRequestRevisionForm):
      Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl+`/staff/kyc/${id}/request-revision`,
        data,
    );
  }

  editUserInfo(id:string, data: Partial<KycUpdatableInfo>):
  Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl+`/staff/kyc/${id}`,
        data,
    );
  }

  pushAttachment(id:string, url: string):
  Observable<RemitApiResponse> {
    return this.getKYCFormDetails(id).pipe(
        switchMap((kyc)=>{
          const existing = kyc.attachments ?? [];
          return this.http.put<RemitApiResponse>(
              this.remitApiUrl+`/staff/kyc/${id}`,
              {
                attachments: [
                  ...existing,
                  url,
                ],
              },
          );
        }),
    );
  }

  removeAttachmentAt(id:string, position: number):
  Observable<RemitApiResponse> {
    return this.getKYCFormDetails(id).pipe(
        switchMap((kyc)=>{
          return this.http.put<RemitApiResponse>(
              this.remitApiUrl+`/staff/kyc/${id}`,
              {
                attachments: kyc.attachments?.filter(
                    (_, index) => index != position),
              },
          );
        }),
    );
  }

  createComment(id: string, comment: string)
  : Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.remitApiUrl + `/staff/kyc/${id}/comment`,
        {
          content: comment,
        },
    );
  }

  queryComments(id: string, params: PaginationParams) :
  Observable<KycComments> {
    return this.http.post<KycComments>(
        this.remitApiUrl + `/staff/kyc/${id}/comments`,
        params,
    );
  }

  deleteComment(aggregationId: string, commentId: string)
  : Observable<RemitApiResponse> {
    return this.http.put<RemitApiResponse>(
        this.remitApiUrl +
        `/staff/kyc/${aggregationId}/comment/${commentId}/delete`,
        null,
    );
  }

  autoCheckArcValid(id: string)
  : Observable<RemitApiResponse> {
    return this.http.post<RemitApiResponse>(
        this.autoArcUrl +`/validate-arc`,
        {
          kycFormId: id,
        },
    );
  }


  getARCTypes():
    Observable<KycArcType[]> {
    return this.http.get<{body: KycArcType[]}>(
        this.remitApiUrl+`/staff/arc-types`,
    ).pipe(map((v)=> v.body));
  }

  getARCTypesNames(): Record<KycArcType, string> {
    return {
      student: 'Student-Internship 就學-產學合作專班',
      work: 'Worker 外勞/移工',
      whiteCollar: 'White Collar 應聘',
      permanent: 'Permanent 永久居留證',
      missionaries: 'Missionaries',
      marriage: 'Marriage 依親 - 夫/妻',
      other: 'Other 其他',
    };
  }

  uploadImage(file: File): Observable<string> {
    const formData: FormData = new FormData();

    formData.append('file', file);

    return this.http.post<RemitApiResponse & {url:string}>(
        `${this.remitApiUrl}/staff/upload-image`,
        formData,
    ).pipe(map((v)=>v.url));
  }
}
